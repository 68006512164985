import { Button } from '@mantine/core';
import '../../../src/index.css';

function Filled(props) {
	return (
		<Button
			className="bg-arniko-red font-pop font-normal text-base hover:bg-arniko-red"
			radius="xl"
			size="md"
		>
			{props.tit}
		</Button>
	);
}

export default Filled;
