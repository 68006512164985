import { createContext, useContext, useState } from "react";

interface popupProps{
    styleChange: string
    setStyleChange: React.Dispatch<React.SetStateAction<string>>
    firstTime:boolean
    setFirstTime: React.Dispatch<React.SetStateAction<boolean>>
}

const popupContext = createContext({} as popupProps);

export const usePopupContext = ()=>{
    return useContext(popupContext);
}

export const PopUpContextProvider = ({children}:any) => {
	const [styleChange, setStyleChange] = useState('hidden');
	const [firstTime, setFirstTime] = useState(true);


    return(
        <popupContext.Provider value={{styleChange, setStyleChange, firstTime, setFirstTime}}>
            {children}
        </popupContext.Provider>
    )
}