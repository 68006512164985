import Nav from "../components/common/Nav";
import MainRoute from "../routes";
import Footer from "../components/common/Footer";
import { usePopupContext } from "../contextApi/PopupContextHandler";

export const LandingLayout = () => {
  const { styleChange } = usePopupContext();

  return (
    <>
      {styleChange === "hidden" ? (
        <main className={`font-pop`}>
          <Nav />
          <MainRoute />
        </main>
      ) : (
        <main className={`font-pop`}>
          <Nav />
          <MainRoute />
          <Footer />
        </main>
      )}
    </>
  );
};
