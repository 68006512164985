import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const INITIAL_SIDEBAR_ITEMS = [
    {
      label: "Pre School ",
      link: "/Preschool",
    },
    {
      label: "Primary Years Curriculum ",
      link: "/primary",
    },
    {
      label: "Middle Years Curriculum ",
      link: "/middle",
    },
    // {
    //   label: "Arniko College ",
    //   link: "/arniko-college",
    // },

    // {
    //   label: "Socio-Cultural Values",
    //   link: "/culture",
    // },
  ];
  const [sidebarItems, setSidebarItems] = useState([...INITIAL_SIDEBAR_ITEMS]);
  return (
    <div>
      <ul className=" ">
        {sidebarItems.map((v, key) => (
          <li
            key={key}
            className={`font-medium  cursor-pointer text-xl p-4 hover:text-gray hover:translate-x-6  hover:ease-in-out duration-300   ${
              location.pathname === `${v.link}` ? "text-arniko-red" : ""
            }`}
            onClick={() => navigate(v.link)}
          >
            {v.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
