import FacultyDetailPage from "../../components/common/FacultyDetailPage";
import Humanities from '../../assets/images/humanities.png';

const HumanitiesFaculty = () => {
  return (
    <div>
      <FacultyDetailPage
        name="Humanities"
        img={Humanities}
        desc="The humanities include the study of all languages and literatures, the arts, history, and philosophy. Humanities stream has a wider horizon that give you a plethora of career options in various sectors. This stream gives an immense insight about human society, its reality, customs, cultures, traditions and so on. A humanistic education in ARNIKO will help you understand, appreciate, and produce art, music, theatre, and literature. Humanities disciplines focus on understanding beauty and the good, and give students the opportunity to practice making good and beautiful things themselves."
         p1="Students securing at least 2.0 GPA in SEE or in an equivalent recognized Board with a C grade in all subjects will be eligible to apply. Admission is based on the entrance exam. Students must meet the criteria set by the college."
         p3={['English', 'Nepali', 'Social Studies and Life Skill Educations', 'Major English', 'Mass Communication', 'Sociology', 'Population Studies']}
         p4={[]}
      />
    </div>
  );
};

export default HumanitiesFaculty;
