import { useEffect } from "react";
import HeaderAndParagraph from "./HeaderAndParagraph";

const FacultyDetailPage = ({name, desc, p1, p3, p4, img}:any) => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  
  return (
    <div className="courses">
      <div className="courses-hero-section bg-blue-200  text-white">
        <div className="main-container">
          <div className="hero-section-contents flex justify-between items-center">
            <div className="desc">
              <div className="name">{name}</div>
              <p>
                {desc}
              </p>
            </div>
            <div className="faculty-img">
              <img src={img} />
            </div>
          </div>
        </div>
      </div>

      <div className="model-section">
        <HeaderAndParagraph
          title="Our Model"
          desc="With the team of experienced and dedicated academicians and administrators in charge of delivering higher quality education to students at a world-class infrastructure, Arniko brings pleasure back to the learning process."
        />
        <div className="main-container">
          <div className="model-contents flex justify-between items-center mt-lg">
            <div className="desc">
              <h2>Eligibility</h2>
              <p>
                {p1}
              </p>
              <h2>Scholarship</h2>
              <p>
                {desc}
              </p>
              <h2>Subjects</h2>
              {
                p3?.map((item:any, key:number)=><li key={key}>{item}</li>)
              }
              {p4?.length>0 && <div>
                <h2>Optional Subjects</h2>
                <div>
                    {
                        p4?.map((item:any, key:number)=><li key={key}>{item}</li>)
                    }
                </div>
              </div>
              }
            </div>

            <div className="img">
              <img src={img} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacultyDetailPage;
