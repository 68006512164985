import TitleComponent from "./TitleComponent"


const HeaderAndParagraph = ({title,desc}:any) => {
  return (
    <div className="text-center header-para-section">
      <div className="welcome-header">
        <TitleComponent title={title}/>
      </div>
        <div className="welcome-desc">
            {desc}
        </div>
    </div>
  )
}

export default HeaderAndParagraph
