import { Link } from "react-router-dom";

const ButtonRed = ({ to, value }: any) => {
  return (
    <div className="main-button-red">
      <Link to={to}>{value}</Link>
    </div>
  );
};

export default ButtonRed;
