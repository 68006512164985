// import {
//   Alert,
//   Button,
//   FileInput,
//   Grid,
//   Group,
//   Input,
//   Paper,
//   Radio,
//   Select,
//   Text,
//   Textarea,
// } from "@mantine/core";
// import NepaliDateConverter from "nepali-date-converter";
// import { Dropzone, FileWithPath } from "@mantine/dropzone";
// import { DatePicker } from "@mantine/dates";
// import { IconAlertCircle, IconUpload } from "@tabler/icons-react";
// import { InputWrapper } from "@mantine/core/lib/Input/InputWrapper/InputWrapper";
// import React, { useState } from "react";
// // import { RadioGroup } from "@mantine/core/lib/Radio/RadioGroup/RadioGroup";

// const Form = (onDrop) => {
//   function handleDragOver(e) {
//     e.preventDefault();
//   }

//   function handleDrop(e) {
//     e.preventDefault();
//     const files = Array.from(e.dataTransfer.files);
//     onDrop(files);
//   }
//   // const [selectedDate, setSelectedDate] = React.useState(new Date());
//   // function convertToNepaliDate(date) {
//   //   const bsDate = new NepaliDateConverter(
//   //     date.getFullYear(),
//   //     date.getMonth() + 1,
//   //     date.getDate()
//   //   );
//   //   return bsDate.format("YYYY-MM-DD");
//   // }

//   const [formData, setFormData] = useState({});

//   function handleSubmit(event) {
//     event.preventDefault();
//     console.log(formData);
//   }
//   function handleChange(event) {
//     setFormData({
//       ...formData,
//       [event.target.name]: event.target.value,
//     });
//   }
//   return (
//     <div className="w-full font-pop text-sm mx-auto py-4 lg:w-[60%]">
//       <Paper shadow="xl" radius="md" p="md">
//         <form onSubmit={handleSubmit}>
//           <div className="details text-center mb-6 ">
//             <Text className=" font-pop text-arniko-red  text-xl font-medium lg:text-2xl">
//               ARNIKO INT'L SECONDARY SCHOOL
//             </Text>
//             <Text className="text-xs font-pop">
//               TALCHHIKHEL , LALITPUR
//               <br />
//               +(977) 01-5529905 | +(977) 01-5550084 | arnikoinfo@gmail.com
//             </Text>
//           </div>

//           <Text className="text-arniko-red py-3 font-pop">
//             Fields with (*) are compulsory.
//           </Text>

//           <div className="applied">
//             <Radio.Group
//               name="favoriteFramework"
//               label="STREAM / PROGRAMME APPLIED FOR "
//               withAsterisk
//               className="py-4"
//             >
//               <Group mt="xs">
//                 <Radio value="Law" label="Law" onClick={handleChange} />
//                 <Radio
//                   value="Management"
//                   label="Management"
//                   onClick={handleChange}
//                 />
//                 <Radio
//                   value="Humanities"
//                   label="Humanities"
//                   onClick={handleChange}
//                 />
//                 <Radio value="Science" label="Science" onClick={handleChange} />
//               </Group>
//             </Radio.Group>

//             <Grid className="pb-10">
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" withAsterisk label="First Name">
//                   <Input
//                     id="input-demo"
//                     placeholder="First Name"
//                     name="firstName"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" label="Middle Name">
//                   <Input
//                     id="input-demo"
//                     placeholder="Middle Name"
//                     name="middleName"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" withAsterisk label="Last Name">
//                   <Input
//                     id="input-demo"
//                     placeholder="Last Name"
//                     name="lastName"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//             </Grid>
//           </div>

//           <div className="personal">
//             <Text className=" bg-arniko-red text-center text-lg font-pop py-1 text-white">
//               PERSONAL INFORMATION
//             </Text>

//             <Grid className="py-6">
//               <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                 <div>
//                   <Input.Wrapper label="Date of birth " required>
//                     <DatePicker
//                       placeholder="YYYY-MM-DD"
//                       label="BS"
//                       className="my-2"
//                       name="BS"
//                       onChange={handleChange}
//                     />
//                     <DatePicker
//                       placeholder="YYYY-MM-DD"
//                       label="AD"
//                       className="my-2"
//                       name="AD"
//                       onChange={handleChange}
//                     />

//                     {/* <DatePicker
//                       label="Select a date"
//                       value={selectedDate}
//                       onChange={(date) => setSelectedDate(date)}
//                       inputFormat={(date) => convertToNepaliDate(date)}
//                     /> */}
//                   </Input.Wrapper>
//                 </div>
//               </Grid.Col>
//               <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                 <Radio.Group label="Gender" required>
//                   <Radio
//                     value="Male"
//                     label="Male"
//                     name="male"
//                     onChange={handleChange}
//                   />
//                   <Radio
//                     value="Female"
//                     label="Female"
//                     name="female"
//                     onChange={handleChange}
//                   />
//                   <Radio
//                     value="Other"
//                     label="Other"
//                     name="other"
//                     onChange={handleChange}
//                   />
//                 </Radio.Group>
//               </Grid.Col>
//               <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                 <Select
//                   label="Nationality"
//                   placeholder="Nepali"
//                   searchable
//                   withAsterisk
//                   nothingFound="No options"
//                   name="nationality"
//                   data={[
//                     "Afgan/Afgani",
//                     "Bangladeshi",
//                     "Chinese",
//                     "Indian",
//                     "Nepali",
//                     "Pakistani",
//                     "Sri Lankan",
//                   ]}
//                   onChange={handleChange}
//                 />
//               </Grid.Col>
//               <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" label="Email">
//                   <Input
//                     id="input-demo"
//                     placeholder="Your email"
//                     name="email"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>

//               <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" withAsterisk label="Address">
//                   <Input
//                     id="input-demo"
//                     name="address"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//               <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                 <Input.Wrapper
//                   id="input-demo"
//                   withAsterisk
//                   label="Mobile Number"
//                 >
//                   <Input
//                     id="input-demo"
//                     name="mobileNumber"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>

//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" withAsterisk label="Province">
//                   <Input
//                     id="input-demo"
//                     name="province"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" withAsterisk label="District">
//                   <Input
//                     id="input-demo"
//                     name="district"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <Input.Wrapper
//                   id="input-demo"
//                   withAsterisk
//                   label="Municipality"
//                 >
//                   <Input
//                     id="input-demo"
//                     name="municipality"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//             </Grid>
//           </div>

//           <div className="academic">
//             <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
//               ACADEMIC INFORMATION
//             </Text>
//             <Grid className="pb-9 pt-8">
//               <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" label="Previous School's Name :">
//                   <Input
//                     id="input-demo"
//                     name="schoolName"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//               <Grid.Col lg={3} md={6} sm={6} xs={0}>
//                 <Input.Wrapper id="input-demo" label="Appeared Year:">
//                   <Input
//                     id="input-demo"
//                     name="appearedYear"
//                     onChange={handleChange}
//                   />
//                 </Input.Wrapper>
//               </Grid.Col>
//               <Grid.Col lg={3} md={6} sm={6} xs={0}>
//                 <Input.Wrapper
//                   id="input-demo"
//                   withAsterisk
//                   label="Percentage/GPA:"
//                 >
//                   <Input id="input-demo" name="GPA" onChange={handleChange} />
//                 </Input.Wrapper>
//               </Grid.Col>
//             </Grid>
//           </div>

//           <div className="parents">
//             <div className="father">
//               <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
//                 PARENT'S INFORMATION
//               </Text>
//               <Grid className="pb-9 pt-8">
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper id="input-demo" label="Father's Name">
//                     <Input
//                       id="input-demo"
//                       name="fathersName"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper id="input-demo" label="Mobile Number">
//                     <Input
//                       id="input-demo"
//                       name="mobileNumber"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper
//                     id="input-demo"
//                     withAsterisk
//                     label="Nationality"
//                   >
//                     <Input
//                       id="input-demo"
//                       name="nationality"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper id="input-demo" label="Address">
//                     <Input
//                       id="input-demo"
//                       name="address"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//               </Grid>
//             </div>
//             <hr className="text-hr" />
//             <div className="mothers">
//               <Grid className="pb-9 pt-8">
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper id="input-demo" label="Mother's Name">
//                     <Input
//                       id="input-demo"
//                       name="mothersName"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper id="input-demo" label="Mobile Number">
//                     <Input
//                       id="input-demo"
//                       name="mobileNumber"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper
//                     id="input-demo"
//                     withAsterisk
//                     label="Nationality"
//                     onChange={handleChange}
//                   >
//                     <Input
//                       id="input-demo"
//                       name="nationality"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//                 <Grid.Col lg={6} md={6} sm={6} xs={0}>
//                   <Input.Wrapper id="input-demo" label="Address">
//                     <Input
//                       id="input-demo"
//                       name="address"
//                       onChange={handleChange}
//                     />
//                   </Input.Wrapper>
//                 </Grid.Col>
//               </Grid>
//             </div>
//           </div>

//           <div className="docs">
//             <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
//               SUPPORTING DOCUMENTS
//             </Text>

//             <Grid className="pb-9 pt-8">
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <div
//                   onDragOver={handleDragOver}
//                   onDrop={handleDrop}
//                   style={{
//                     border: "1px dashed gray",
//                     padding: "1rem",
//                     textAlign: "center",
//                   }}
//                 >
//                   Drag and drop files here
//                 </div>
//               </Grid.Col>
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <FileInput
//                   label="Document"
//                   placeholder="No file chosen"
//                   icon={<IconUpload />}
//                   onChange={handleChange}
//                 />
//               </Grid.Col>
//               <Grid.Col lg={4} md={6} sm={6} xs={0}>
//                 <FileInput
//                   label="Others(if any)"
//                   placeholder="No file chosen"
//                   icon={<IconUpload />}
//                   onChange={handleChange}
//                 />
//               </Grid.Col>
//             </Grid>
//             <div className="alert py-4">
//               <Alert
//                 icon={<IconAlertCircle size="1rem" />}
//                 color="gray"
//                 variant="filled"
//               >
//                 Please click on the image to upload or change it. Accepted file
//                 size: Max 1MB.
//               </Alert>
//             </div>
//           </div>

//           <div className="others">
//             <Text className="bg-arniko-red text-center text-lg font-pop py-1 text-white">
//               OTHERS
//             </Text>
//             <Textarea
//               label="Write your query(if any):"
//               className="pb-9 pt-8"
//               name="others"
//               onChange={handleChange}
//             />
//           </div>
//           <div className="submit flex justify-center">
//             <Button
//               className="bg-arniko-red font-pop font-normal text-base hover:bg-dblue text-center"
//               type="submit"
//             >
//               Submit
//             </Button>
//           </div>
//         </form>
//       </Paper>
//     </div>
//   );
// };

// export default Form;

const Form = () => {
	return (
		<iframe
			src="schoolForm.html"
			className="w-screen h-screen  overflow-x-hidden"
		></iframe>
	);
};

export default Form;
