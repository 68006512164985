import React from 'react'

const TitleComponent = ({title}:any) => {
  return (
    <div className='title-component'>
      {title}
    </div>
  )
}

export default TitleComponent
