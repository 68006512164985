import { Route, Routes } from 'react-router-dom';
import AboutUs from '../pages/auth/AboutUs';
import ArnikoCollege from '../pages/auth/ArnikoCollege';
import Contentpage from '../pages/auth/Content.page';
import Form from '../pages/auth/Form';
import HumanitiesFaculty from '../pages/faculty/HumanitiesFaculty';
import LawFaculty from '../pages/faculty/LawFaculty';
import ManagementFaculty from '../pages/faculty/ManagementFaculty';
import ScienceFaculty from '../pages/faculty/ScienceFaculty';

const MainRoute = () => {
	return (
		<Routes>
			{/* <Route path={''} element={<LandingPage />} /> */}
			<Route path="/" element={<ArnikoCollege/>}/>
			<Route path={'/:category'} element={<Contentpage />} />
			<Route path={'/form'} element={<Form />} />
			<Route path='/about' element={<AboutUs/>}/>
			<Route path="/senior/courses/science" element={<ScienceFaculty/>}/>
			<Route path="/senior/courses/management" element={<ManagementFaculty/>}/>
			<Route path="/senior/courses/law" element={<LawFaculty/>}/>
			<Route path="/senior/courses/humanities" element={<HumanitiesFaculty/>}/>
		</Routes>
	);
};

export default MainRoute;
