import FacultyDetailPage from '../../components/common/FacultyDetailPage';
import Law from '../../assets/images/law.png'

const LawFaculty = () => {
  return (
    <div>
      <FacultyDetailPage
      name="Law"
      img={Law}
      desc="The primary goal of the Faculty of Law is to provide in-depth knowledge of the law at its level. It aims to produce future law professionals. The diverse array of options that a law degree opens can be pursued as per one’s interests and inclinations. It can be a gateway to not only money and success but also to power and respect. The stream of law, one decides to focus on and continues for a long way determines the nature of work they would be doing. The study of law will equip you with the ability to deal with challenging issues, understand human logic, and analyze real-life cases along with logical clarity and a good grip over oral/written communication. Your success in this field will have a lot to do with your personality. Currently, the study of law is one of the most dynamic and ever-growing fields."
      p1="Students securing at least 2.0 GPA in SEE or in an equivalent recognized Board with a C grade in all subjects will be eligible to apply.
      Admission is based on the entrance exam. Students must meet the criteria set by the college."
      p3={[
        "English",
        "Nepali",
        "Social Studies and Life Skill Educations",
        "Constitutional Law (XI)",
        "Procedural Law (XI)",
        "Jurisprudence and Legal Theories (XI)",
        "Civil and Criminal Law (XII)",
        "Nepalese Legal System (XII)",
        "Legal Drafting (XII)"
      ]}
      p4={[]}
    />
    </div>
  )
}

export default LawFaculty
