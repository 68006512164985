import { useParams } from "react-router-dom";
import Middle from "../../../pages/auth/Middle";
// import ArnikoCollege from "../../../pages/auth/ArnikoCollege";
import ArnikoCollege from "../../../pages/auth/ArnikoCollege";
import Primary from "../../../pages/auth/Primary";

const MainContent = () => {
  const { category } = useParams();

  switch (category) {
    case "Primary":
      return <Primary />;
    case "middle":
      return <Middle />;
    case "arniko-college":
      return <ArnikoCollege />;
    // case "Preschool":
    //   return <Preschool />;

    default:
      return <Primary />;
  }
};

export default MainContent;
