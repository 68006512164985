import { Link } from "react-router-dom";
import ArnikoMap from "../../assets/images/map.png";
import ScienceImage from "../../assets/images/science.png";
import ManagementImage from "../../assets/images/management.png";
import HumanitiesImage from "../../assets/images/humanities.png";
import FacebookLike from "../../assets/images/facebookLike.png";
import ArnikoClub from "../../assets/images/arnikoclub.png";
import Journal from "../../assets/images/journal.png";

import LawImage from "../../assets/images/law.png";
import HeaderAndParagraph from "../../components/common/HeaderAndParagraph";
import TitleComponent from "../../components/common/TitleComponent";
import TopImg from "../../assets/icons/material-symbols_keyboard-arrow-down-rounded.png";
import ButtonRed from "../../components/common/ButtonRed";

import G1 from "../../assets/images/gallery/g1.png";
import G2 from "../../assets/images/gallery/g2.png";
import G3 from "../../assets/images/gallery/g3.png";
import G4 from "../../assets/images/gallery/g4.png";
import G5 from "../../assets/images/gallery/g5.png";
import G6 from "../../assets/images/gallery/g6.png";
import G7 from "../../assets/images/gallery/g7.png";
import G8 from "../../assets/images/gallery/g8.png";

import Discipline from "../../assets/icons/discipline.png";
import Responsibility from "../../assets/icons/responsibility.png";
import Natural from "../../assets/icons/natural.png";
import Skill from "../../assets/icons/skil.png";
import { useEffect } from "react";
import "../../main.scss";
import { usePopupContext } from "../../contextApi/PopupContextHandler";

const ArnikoCollege = () => {
  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const { setFirstTime, setStyleChange } = usePopupContext();

  useEffect(() => {
    setFirstTime(false);
    setStyleChange("auto");
  }, []);

  const date = new Date();
  const day = date.toLocaleDateString("en-US", { weekday: "long" });
  const nbrDay = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const facultyArr = [
    {
      image: ScienceImage,
      name: "science",
      desc: "The primary goal of the Faculty of Science is to provide in-depth knowledge of the science at its level.",
      link: "/senior/courses/science",
    },
    {
      image: ManagementImage,
      name: "management",
      desc: "The primary goal of the Faculty of Management is to provide in-depth knowledge of management.",
      link: "/senior/courses/management",
    },
    {
      image: LawImage,
      name: "law",
      desc: "The primary goal of the Faculty of Law is to provide in-depth knowledge of the law at its level.",
      link: "/senior/courses/law",
    },
    {
      image: HumanitiesImage,
      name: "humanities",
      desc: "The humanities include the study of all languages and literatures, the arts, history, and philosophy.",
      link: "/senior/courses/humanities",
    },
  ];

  const whyArnikoArr = [
    {
      title: "Academic Excellence",
      desc: "We strive for academic excellence by offering a wide range of programs and courses designed to meet the demands of today's competitive world. Our courses are NEB based but made practical and enjoyable with the right blend of IT co-curricula and robotics. Our dedicated faculty members are experts.",
    },
    {
      title: "State-of-the-Art Facilities",
      desc: "Arniko is equipped with modern facilities, including well-maintained classrooms, laboratories, libraries, computer labs and robotics lab. We believe that a conducive learning environment plays a positive catalytic role in shaping the educational experience of the student.",
    },
    {
      title: "Holistic Development",
      desc: "At Arniko, we have a firm understanding that education is not limited to textbooks and classrooms. Quite opposite, education becomes more enjoyable and efficient when received out of the classroom bounds. We emphasize the holistic development of every student by providing ample opportunities for extracurricular activities, sports, cultural events, and community engagement through our clubs.",
    },
    {
      title: "Supportive Community",
      desc: "We promote and take immense pride in our inclusive and supportive community, where students are encouraged to express themselves, collaborate, and grow. Our faculty and staff are committed to providing guidance and support throughout your academic journey.",
    },
    {
      title: "IT based curriculum",
      desc: "Arniko focuses on practical skill based IT curricula that students can apply throughout to a practical project in their field of study, such as creating a website, conducting data analysis, developing a simple application or simply troubleshooting simple issues in their computers.",
    },
    {
      title: "Scholarships and Financial Aid",
      desc: "We believe that financial constraints should not hinder your educational aspirations. We offer various scholarships and financial aid options to deserving students, enabling them to pursue their dreams without undue financial burden.",
    },
    {
      title: "Industry-Relevant Programs",
      desc: "Our programs are based on NEB. However, we foster a practical learning approach, incorporating internships, industry collaborations, and guest lectures by professionals to ensure that our students are well-prepared for the real world.",
    },
  ];

  // const ArnikoDetailArr = [
  //   {
  //     icon: WebImg,
  //     value: "50+",
  //     title: "Student Nationalities",
  //   },
  //   {
  //     icon: TickImg,
  //     value: "36.5",
  //     title: "Average IB Score",
  //   },
  //   {
  //     icon: ResultImg,
  //     value: "37%",
  //     title: "IB Bilingual Diplomas",
  //   },
  //   {
  //     icon: UserImg,
  //     value: "55+",
  //     title: "Clubs and teams",
  //   },
  // ];

  const galleryImg = [
    {
      img: G1,
    },
    {
      img: G2,
    },
    {
      img: G3,
    },
    {
      img: G4,
    },
    {
      img: G5,
    },
    {
      img: G6,
    },
    {
      img: G7,
    },
    {
      img: G8,
    },
  ];

  const ourCoreValues = [
    {
      img: Discipline,
      title: "Discipline",
      desc: "As we vision to become a learning school that's beyond academic knowledge, our definition of discipline also goes beyond the school environment. We teach students to value life, career, parents, and every being. We help them understand ethics and moral values and help them shape their meaningful character.",
    },
    {
      img: Responsibility,
      title: "Responsibility",
      desc: "At Arniko, students will learn to become responsible children, professionals, and eventually responsible citizens. We believe in education that teaches students to become accountable to their own life, become wise, and enjoy their journey at the same time.",
    },
    {
      img: Natural,
      title: "Natural Instinct & Individuality",
      desc: "Every child is unique; therefore, their talents, interests, and challenges are unique. Our management and faculty value the individual traits of students and help them discover their best versions based on their natural inclinations and passion.",
    },
    {
      img: Skill,
      title: "Soft skills",
      desc: "The real world demands more than just paper knowledge. Arniko focuses on skills that shape students into humble beings, heightens their positive aura, and prepares a solid base for future challenges.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="arniko-home">
      {/* video section  */}
      <div className="video-intro-section">
        <iframe
          className="arniko-video"
          src="https://www.youtube.com/embed/lyMvKtBnXjA?rel=0&autoplay=1&loop=1&modestbranding=1&rel=0"
          title="Arniko College Promotional Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      {/* welcome section  */}
      <div className="welcome-to-arniko-college">
        <HeaderAndParagraph
          title="Welcome to Arniko College"
          desc=" At Arniko, we are committed to providing exceptional educational
          experience and empowering our students to excel in their academic
          journey. Arniko’s +2 programs are blended with recent IT based
          co-curricula that serves as a gateway to explore the world of
          opportunities and possibilities that await you in the near future."
        />
        <div
          className="arniko-faculties flex  justify-between flex-wrap"
          id="courses"
        >
          {facultyArr?.map((faculty: any, key: number) => (
            <div className="faculty-box" key={key}>
              <div className="image-section relative">
                <img className="faculty-image" src={faculty?.image} alt="" />
                <div className="faculty-name text-center py-sm absolute left-0 bottom-0">
                  {faculty?.name}
                </div>
                <div className="faculty-overlay flex justify-center items-center">
                  <ButtonRed to={faculty?.link} value="read more" />
                </div>
              </div>
              <div className="faculty-paragraph-section text-center">
                {faculty?.desc}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* our core values  */}
      <div className="our-core-values ">
        <div className="text-center" style={{marginBottom:'40px'}}>
          <TitleComponent title="Our Core Values" />
        </div>
        <div className="core-contents flex flex-wrap mt-md bg-blue-200">
          {ourCoreValues?.map((item: any, key: number) => (
            <div className="core-box bg-blue-200" key={key}>
              <div className="img">
                <img src={item?.img} />
              </div>
              <div className="title">{item?.title}</div>
              <div className="desc">{item?.desc}</div>
            </div>
          ))}
        </div>
      </div>
      {/* why arniko  */}
      <div className="why-arniko-section text-center">
        <TitleComponent title="Why choose Arniko’s +2 programs ?" />
        <div className="main-container">
          <div className="arniko-programs-reason flex justify-between flex-wrap" style={{marginTop:'40px'}}>
            {whyArnikoArr?.map((item: any, key: number) => (
              <div className="reason-box" key={key}>
                <div className="title">{item?.title}</div>
                <div className="desc">{item?.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* life at arniko  */}
      <div className="life-at-arniko flex items-center flex-wrap">
        {/* <div className="life-at-arniko-box flex justify-center items-center flex-col bg-red-500 text-white">
          <div
            className="value"
            style={{ fontSize: "7rem", fontWeight: "600" }}
          >
            5
          </div>
          <div
            className="title"
            style={{ fontSize: "2.5rem", fontWeight: "500" }}
          >
            overcast clouds
          </div>
        </div> */}
        {/* <div className="life-at-arniko-box flex justify-center items-center flex-col text-center bg-blue-200 ">
          <svg
            stroke="currentColor"
            fill="#fff"
            stroke-width="0"
            viewBox="0 0 24 24"
            className="text-6xl font-bold mx-auto pt-3"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M12 3a9 9 0 000 18c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
          </svg>
          <p
            className="text-red-500 my-xs"
            style={{ fontSize: "1.5rem", fontWeight: "500" }}
          >
            Celebrating a range of talents in the performing Arts
          </p>
          <p
            className="text-white px-sm"
            style={{ fontSize: "1.5rem", fontWeight: "500" }}
          >
            Children can take a dare and express themselves by acting, singing,
            and dancing exploring the creative side of their personalities
          </p>
          <p
            className="mt-xs text-white"
            style={{ fontSize: "1.8rem", fontWeight: "500" }}
          >
            SIGN UP FOR NEWS ALERTS
          </p>
        </div> */}
        {/* <div className="life-at-arniko-box flex justify-center items-center">
          <img src={Sports} />
        </div> */}
        <div className="life-at-arniko-box flex justify-center items-center flex-col bg-blue-200 text-white">
          <p
            className="capitalize"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            {day}
          </p>
          <p style={{ fontSize: "70px", fontWeight: "600" }}>{nbrDay}</p>
          <p
            className="capitalize"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >{`${month} ${year}`}</p>
          <p className="mt-xs" style={{ fontSize: "22px", fontWeight: "500" }}>
            SCHOOL YEAR CALENDAR
          </p>
        </div>
        <div className="life-at-arniko-box flex justify-center items-center">
          {/* <Link to=""> */}
          <img src={ArnikoClub} />
          {/* </Link> */}
        </div>
        <div className="life-at-arniko-box flex justify-center items-center">
          <Link to="https://www.facebook.com/arnikointl.school/">
            <img src={FacebookLike} />
          </Link>
        </div>
        <div className="life-at-arniko-box flex justify-center items-center">
          {/* <Link to=""> */}
          <img src={Journal} />
          {/* </Link> */}
        </div>
      </div>
      

      {/* <div className="arniko-details-section flex items-center justify-between">
        {ArnikoDetailArr?.map((item: any, key: number) => (
          <div
            className="details-box flex items-center flex-col justify-center"
            key={key}
          >
            <div className="icon">
              <img src={item?.icon} alt="img" />
            </div>
            <div className="value">{item?.value}</div>
            <div className="title">{item?.title}</div>
          </div>
        ))}
      </div> */}

      {/* gallery */}
      <div className="arniko-gallery">
        {/* <HeaderAndParagraph
          title="Gallery"
          desc="ICS provides an empowering learning environment where all members of the community are challenged to achieve their potential, encouraged to pursue their passions, and expected to fulfil their responsibilities. Numerous programmes support students and parents through all stages of their ICS journey."
        /> */}
        <div className="title-section">
          <TitleComponent title="Gallery" />
        </div>
        <div className="gallery-section mt-lg">
          <div className="gallery">
            {galleryImg?.map((item: any, key: number) => (
              <div className="gallery-item" key={key}>
                <img className="gallery-image" src={item?.img} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* map section  */}
      <div className="arniko-map relative">
        <img src={ArnikoMap} alt="arniko map" />
        <div
          className="scroll-to-top absolute flex justify-center items-center cursor-pointer"
          onClick={() => goToTop()}
        >
          <img src={TopImg} />
        </div>
      </div>
      {/* footer section  */}
    </div>
  );
};

export default ArnikoCollege;
