import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { MainContainer } from "./hoc/main";
import MainRoute from "./routes";
import { LandingLayout } from "./layouts/Landing.layout";
import { PopUpContextProvider } from "./contextApi/PopupContextHandler";

function App() {
  return (
    <Provider store={store}>
      <PopUpContextProvider>
           <MainContainer>
          <LandingLayout />
        </MainContainer>
      </PopUpContextProvider>
    </Provider>
  );
}

export default App;
