import FacultyDetailPage from '../../components/common/FacultyDetailPage'
import Management from '../../assets/images/management.png'

const ManagementFaculty = () => {
  return (
    <div>
       <FacultyDetailPage
        name="Management"
        img={Management}
        desc="The primary goal of the Faculty of Management is to provide in-depth knowledge of management at its level. It aims to produce future managers and chartered accountants, business analysts, E-commerce, financial institutions as well as Information Technology. It focuses on innovative technologies to efficiently run different businesses. The future of management relies on flexibility, adaptation, and pragmatism. Management innovation means using technology to help transform organizations. Innovation must be rooted in organizational fabric and culture to be sustainable."
         p1="Students securing at least 2.0 GPA in SEE or in an equivalent recognized Board with a C grade in all subjects will be eligible to apply.
         Admission is based on the entrance exam. Students must meet the criteria set by the college."
         p3={['English', 'Nepali', 'Social Studies and Life Skill Educations', 'Accounting', 'Economics']}
         p4={['Business Studies','Computer Science', 'Hotel Management']}
      />
    </div>
  )
}

export default ManagementFaculty
