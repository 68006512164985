import FacultyDetailPage from "../../components/common/FacultyDetailPage";
import Science from "../../assets/images/science.png";

const ScienceFaculty = () => {
  return (
    <FacultyDetailPage
      name="Science"
      img={Science}
      desc="ARNIKO aims to create a foundation for future doctors, engineers, agriculturists, forest officials, and scientists. To meet its goal ARNIKO provides the best quality education which assists to visualize the true potential of students in various fields of science. It not only focuses on National Examination Board’s syllabus but also focuses on the preparation of competitive examinations of medical courses. It conducts free pre-medical classes to make students competent in such examinations. As a result, a number of students have been selected for full scholarships in examinations conducted by respective universities."
      p1="Minimum GPA: 3.0 above with Optional Mathematics. Minimum Grade points: C+ in all subjects.
         Students securing at least 2.85 GPA in SEE or in an equivalent recognized board with B+ grade each in Science, Compulsory Mathematics, Optional Mathematics, and English will be eligible for the science stream.     
         Admission is based on the entrance exam. Students must meet the criteria set by the college."
      p3={[
        "English",
        "Nepali",
        "Social Studies and Life Skill Educations",
        "Physics",
        "Chemistry",
        "Biology ( Botany + Zoology)",
        "Mathematics",
        "Computer Science"
      ]}
      p4={[]}
    />
  );
};

export default ScienceFaculty;
