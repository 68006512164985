import { Button, Drawer } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { usePopupContext } from '../../contextApi/PopupContextHandler';

const Nav = () => {
	const [opened, setOpened] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.pageYOffset;
			setIsScrolled(scrollTop > 0);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	// const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const { setFirstTime, setStyleChange } = usePopupContext();

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
		setFirstTime(false);
        setStyleChange("auto");
	};
	const dropdownRef = useRef<HTMLDivElement>(null);
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};
	useEffect(() => {
		window.addEventListener('click', handleClickOutside);

		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const modelOpen = ()=>{
        setOpened(true);
		setFirstTime(false);
        setStyleChange("auto");
	}

	return (
		<>
			<nav className="flex justify-between p-2 sticky top-0 z-20 bg-white ">
				{/* <nav
				className={`sticky flex justify-between p-2 top-0 w-full z-50 transition bg-transparent duration-500 ${
					isScrolled ? 'bg-white' : 'bg-transparent'
				}`}
			> */}
				<Link to="/">
					<img
						className="h-[45px] w-[150px]"
						src="/assets/logo.png"
						alt="logo"
					/>
				</Link>

				<div className="nav__buttons flex justify-around items-center ">
						<Link 
						className="mr-2  font-pop font-medium text-base about-us-button  text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms hidden  lg:inline-block "
						to={"/about"}>About Us</Link>
					{/* 
					<Link to="/form">
						<Button
							variant="outline"
							radius="xl"
							size="md"
							className="mr-2 font-pop font-medium text-base text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms lg:inline-block  "
						>
							Form
						</Button>
					</Link> */}
					<div ref={dropdownRef}>
						<button
							className=" py-1 px-4 border mx-3 rounded-full  text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms flex  items-center arniko-form-button"
							onClick={toggleDropdown}
						>
							Form <RiArrowDropDownFill className="text-3xl" />
						</button>
						{isOpen && (
							<div className="absolute bg-white shadow-md mt-2  w-48">
								<Link
									to="/form"
									onClick={() => setIsOpen(false)}
									className="block px-4 py-2 my-2 text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms"
								>
									Arniko School Form
								</Link>
								<a
									href="https://forms.gle/Fxw1YspCnHm7TCu98"
									className="block px-4 py-2  text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms"
								>
									Arniko College form
								</a>
							</div>
						)}
					</div>
					<Button
						variant="outline"
						radius="xl"
						size="md"
						className="font-pop font-medium text-base text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms "
						onClick={modelOpen}
					>
						<div className="flex gap-2 items-center">
							Menu
							<AiOutlineMenu />
						</div>
					</Button>
				</div>
			</nav>
			<Drawer
				position={'right'}
				size={300}
				opened={opened}
				onClose={() => setOpened(false)}
			>
				<div className="flex flex-col gap-4 cursor-pointer font-open font-base text-lg text-left ml-6">
				    <Link 
						className="mr-2  font-pop font-medium text-base about-us-button hide-component  text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms  lg:inline-block "
						to={"/about"}
						onClick={() => setOpened(false)}
						>
							About Us
					</Link>
				    <div ref={dropdownRef}>
						<button
							className=" py-1 px-4 border rounded-full  text-light-text hide-component border-light-text hover:bg-blk/20 transition-all ease-in 3ms flex  items-center "
							onClick={toggleDropdown}
						>
							Form <RiArrowDropDownFill className="text-3xl" />
						</button>
						{isOpen && (
							<div className="absolute bg-white shadow-md mt-2  w-48">
								<Link
									to="/form"
									onClick={() => setIsOpen(false)}
									className="block px-4 py-2 my-2 text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms"
								>
									Arniko School Form
								</Link>
								<a
									href="https://forms.gle/Fxw1YspCnHm7TCu98"
									className="block px-4 py-2  text-light-text border-light-text hover:bg-blk/20 transition-all ease-in 3ms"
								>
									Arniko College form
								</a>
							</div>
						)}
					</div>
					<Link
						to="/Preschool"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						Pre-School
					</Link>
					<Link
						to="/primary"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						Primary Years Curriculum
					</Link>
					<Link
						to="/middle"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						Middle Years Curriculum
					</Link>
					<Link
						to="/senior"
						onClick={() => setOpened(false)}
						className=" font-medium text-lg p-2 hover:text-gray hover:-translate-x-6  hover:ease-in-out duration-300"
					>
						+2 Curriculum
					</Link>
				</div>
			</Drawer>
		</>
	);
};

export default Nav;
