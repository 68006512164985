import React from "react";
import Filled from "../../components/common/Filled";
import Features from "../../components/common/Features";
import { Link } from "react-router-dom";

const Art = () => {
  return (
    <>
      <div>
        <h1 className=" text-4xl  font-normal text-center">
          The Middle Years Curriculum
        </h1>
        <p className="text-base font-normal text-center p-3">
          To be able to express emotions through an artistic approach is a
          powerful tool that we help our students of both college and preschool
          experience here at Arniko.
        </p>
        <div className="my-12 ">
          <img
            src="/assets/midbelow.jpg"
            alt="image"
            className=" bg-cover w-full"
          />
        </div>
        <p className="text-base font-normal">
          In Secondary School, everyone is responsible for promoting the
          positive behaviors, known as the five principles of responsible
          learners’ behavior (safe, ready to learn, respectful, responsible,
          kind), needed to support ongoing learning and wellbeing. Behavior
          expectations from students apply at all times, both inside school and
          during school-related activities, tours and excursions outside school.
          This behavior is in fact a culture based on mutual collaboration,
          consistency and ongoing conversations.
          <br />
          <br />
          In Secondary School, the daily application of these principles are
          tracked using behavior charts. Situations can be more nuanced and may
          demand cross-cultural understanding. Teachers regularly discuss the
          school’s expectations and their application in various settings. For
          example, the specifics of the word safety might mean something
          different in Science Laboratories than in sports & game sessions.
        </p>
        {/* <hr className="my-9 text-hr" />
        <div className="quote">
          <h1 className="text-lg font-light p-2 text-arniko-red">
            "When asked to name some of the things she remembers about ICS,
            Georgina has fond memories. She credits ICS with developing her
            sense of solidarity and team spirit, with fostering her interests in
            sports and arts, and with helping her to step out into the world
            beyond "with openness and tolerance that I often miss these days."
          </h1>
          <h1 className=" font-light text-lg p-2">
            Interview with Georgina C, Class of 2000, Art Curator
          </h1>
        </div> */}
        <hr className="my-9 text-hr" />

        <div className="content">
          <div className="literacy">
            <h2 className="text-4xl mb-3 text-dblue py-3">Literacy</h2>
            <div className="develop oral language">
              <h2 className="text-xl  text-arniko-red ">
                Developing oral language (listening and speaking)
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    listen and respond to stories, questions, statements,
                    vocabulary and ask questions when needed
                  </li>
                  <li> talk about the stories they have created</li>
                  <li> follow detailed instructional multi-step directions</li>
                  <li> describe and tell the use of many familiar items</li>
                  <li>
                    generally understood by others, may mispronounce new, long
                    or unusual words
                  </li>
                </ol>
              </p>
            </div>

            <div className="Developing visual communication py-4 ">
              <h2 className="text-xl  text-arniko-red ">
                Developing visual communication (viewing and presenting)
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    show understanding of visual information through discussion,
                    role play and illustrations
                  </li>
                  <li>
                    select and incorporate colors, shapes, symbols, images into
                    visual presentations
                  </li>
                  <li>
                    observe visual images and express that they have been
                    created to achieve particular purposes
                  </li>
                </ol>
              </p>
            </div>
            <div className="Developing as readers py-4  ">
              <h2 className="text-xl  text-arniko-red ">
                Developing as readers
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li> make predictions and anticipate possible outcomes</li>
                  <li>
                    express opinions and make connections to their own
                    experience when listening to or “reading” texts
                  </li>
                  <li> recognize familiar words</li>
                  <li> makes connection with self and story characters</li>
                  <li> recognize sounds in words</li>
                  <li> identify letters of the alphabet by name or sound </li>
                  <li>
                    indicate how a book works e.g. cover, beginning, directional
                    movement and end
                  </li>
                </ol>
              </p>
            </div>
            <div className="	Developing as writers py-4 ">
              <h2 className="text-xl  text-arniko-red ">
                Developing as writers
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    enjoy writing for different reasons, e.g. lists, letters and
                    journal
                  </li>
                  <li> draw and write about topics they are familiar with</li>
                  <li> write and copy words from the local environment</li>
                  <li> write often using letters from their own name</li>
                  <li>
                    hold a marker or crayon with thumb and two fingers to
                    experiment with mark making/writing
                  </li>
                </ol>
              </p>
            </div>
          </div>

          <div className="	Numeracy py-6">
            <h2 className="text-4xl text-dblue py-1">Numeracy</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li>identify and order numbers in the range 0–10 and beyond</li>
                <li>
                  count forwards and backwards, in the range 0–10 and beyond
                </li>
                <li>
                  solve simple problems which require them to add some, take
                  some away, or combine two amounts
                </li>
                <li> recognize patterns to 5, including finger patterns</li>
                <li>
                  count to 5 and beyond by rote e.g. when singing a counting
                  song
                </li>
                <li>
                  describe patterns, using words, drawings, symbols, materials,
                  actions and numbers
                </li>
                <li>
                  use non-standard units to measure and compare the length, mass
                  and capacity of objects
                </li>
                <li> sort, describe and compare 2D and 3D shapes</li>
                <li>
                  represent data and information through tally marks and
                  pictographs
                </li>
              </ol>
            </p>
          </div>
          <div className="Sanskrit Language py-6">
            <h2 className="text-4xl text-dblue py-1"> Sanskrit Language</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li>
                  interact with each other and the teacher using simple language
                </li>
                <li> use or respond to simple everyday words and phrases </li>
                <li> recognize spoken words and names of familiar objects</li>
                <li>begins to make letter sound correspondence in Sanskrit</li>
                <li> draw and make connections to some Sanskrit vocabulary</li>
                <li> Read and recite various slokas</li>
              </ol>
            </p>
          </div>
          <div className="	Social Emotional py-6">
            <h2 className="text-4xl text-dblue py-1"> Social Emotional</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li>
                  is able to describe feelings and their causes in group
                  situations
                </li>
                <li>
                  complete multiple activities in a project of own choosing with
                  some adult assistance
                </li>
                <li>
                  successfully enter a new group or activity and play
                  cooperatively
                </li>
                <li>
                  show increasing awareness that people may have different
                  feelings to themselves about a situation
                </li>
                <li>
                  develop independence in looking after themselves and their
                  property
                </li>
                <li>seek and respond to increasingly physical challenges</li>
              </ol>
            </p>
          </div>

          <div className="Science & Technology py-6">
            <h2 className="text-4xl text-dblue py-1"> Science & Technology</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li> observe changes over time </li>
                <li> use non-standard tools to compare measurements</li>
                <li>
                  discuss what is observed using specific scientific vocabulary
                </li>
                <li> name and describe several attributes of an event</li>
                <li>
                  ask questions about the world around them and discuss
                  scientific ideas
                </li>
                <li>
                  ask questions about the world around them and discuss
                  scientific ideas
                </li>
                <li> identify changes in their immediate environment</li>
                <li>
                  make a prediction based on observations during a scientific
                  investigation
                </li>
                <li>
                  recognise general patterns and offer simple explanations
                  during scientific investigation
                </li>
                <li>
                  draw simple conclusions and demonstrate their understanding
                  using concrete examples
                </li>
              </ol>
            </p>
          </div>

          <div className="	Creative Arts py-6">
            <h2 className="text-4xl mb-3 text-dblue py-3"> Creative Arts</h2>
            <div className="	Visual Arts">
              <h2 className="text-xl  text-arniko-red ">Visual Arts</h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    communicate their response to an artwork visually, orally,
                    physically
                  </li>
                  <li> describe what they notice about an artwork </li>
                  <li> create artwork in response to range of stimuli</li>
                </ol>
              </p>
            </div>

            <div className="	Dance/Drama py-2">
              <h2 className="text-xl  text-arniko-red "> Dance/Drama</h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    communicate and express feelings through body movements
                  </li>
                  <li>
                    talk about ideas and feelings in response to dramatic
                    performances
                  </li>
                  <li> create roles in response to props, set and costumes</li>
                  <li> move their body to express mood of the music</li>
                </ol>
              </p>
            </div>
            <div className="	Music py-2  ">
              <h2 className="text-xl  text-arniko-red "> Music</h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    use vocal sounds, rhythms and instruments to express
                    feelings or ideas
                  </li>
                  <li>
                    demonstrate personal, visual interpretation of elements of
                    sounds e.g. loud/soft, high/low, fast/slow
                  </li>
                </ol>
              </p>
            </div>
          </div>

          <div className="		Design Thinking py-6	">
            <h2 className="text-4xl text-dblue py-1"> Design Thinking</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li>
                  understand that people design to solve problems or improve
                  situations, e.g. organizing spaces to bring people together
                </li>
                <li> know ways to plan and design alone or with a group</li>
                <li>
                  engage with the teacher and classmates to improve and refine
                  their designs
                </li>
                <li>
                  be able to empathize and respond, e.g. by understanding what
                  an audience or visitors to a class exhibition might need
                </li>
                <li> experience the value of persevering with projects</li>
              </ol>
            </p>
          </div>
        </div>
        {/* <Features
          title="Extra-curricular Programme"
          content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
        />
        <Features
          title="Primary School"
          content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
        />
        <Features
          title="Secondary School"
          content={`School provide continuity from the Primary School. Students explore the Arts as a means of both self-expression and, importantly, the expression of ideas. They continue to develop their understanding of diverse cultural traditions and the function of Arts in their own communities. Teamwork and collaboration also continue to be important points of focus and the risk-taking aspect of the Arts is enhanced through greater opportunities to performances for the school community.`}
        /> */}
        <div className="text-center py-4 mb-9">
          {/* <h2 className="text-2xl  text-arniko-red ">
            Unleash your child's artistic voice
          </h2> */}
          <div className="flex gap-2 justify-center py-4">
            <Link to="/form">
              <Filled tit="ADMISSIONS" />
            </Link>
            {/* <Filled tit="PLAN A VISIT" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Art;
