import React from "react";
import Filled from "../../components/common/Filled";
import Features from "../../components/common/Features";
import List from "../../components/common/List";
import { Link } from "react-router-dom";

const Primary = () => {
  return (
    <>
      <div>
        <h1 className=" text-4xl  font-normal text-center">
          The Primary Years Curriculum{" "}
        </h1>
        <p className="text-base font-normal text-center p-3">
          We motivate our students to actively participate in games and sports
          that help them develop an interest in sports, grow sharp focus, and
          help them become better sportsmen.
        </p>
        <div className="my-12 ">
          <img
            src="/assets/skul.jpg"
            alt="image"
            className=" bg-cover w-full"
          />
        </div>
        <p className="text-base font-normal">
          In Primary School, students are expected to learn and understand the
          simple code of conduct of humility, self-care and care and respect for
          others. In the opening weeks of the school year, focused activities
          helped students quickly learn and appreciate this code of conduct.
          Through the grade levels, teachers used a range of age-appropriate
          methods to instill this culture of care.
          <br />
          <br />
          Teachers regularly guide students on how to apply the code when
          situations arise. Children are taught self care through regular
          exercises and yoga. Teachers are allowed to apply a wide variety of
          applications to individual students and the assessment usually
          revolves around how students applied the code. For example, in Primary
          School, the motive for the students is to identify examples of the
          code in action and reflect on how the elements of the code will create
          a positive learning environment for themselves as well as for others.
          Primary assemblies conducted every morning are used to reinforce the
          importance of the code in fun or engaging ways.
        </p>
        <hr className="my-9 text-hr" />
        <div className="quote">
          {/* <h1 className="text-lg font-light p-2 text-arniko-red">
            "When asked to name some of the things she remembers about ICS,
            Georgina has fond memories. She credits ICS with developing her
            sense of solidarity and team spirit, with fostering her interests in
            sports and arts, and with helping her to step out into the world
            beyond "with openness and tolerance that I often miss these days."
          </h1>
          <h1 className=" font-light text-lg p-2">
            Interview with Georgina C, Class of 2000, Art Curator
          </h1> */}
        </div>
        {/* <hr className="my-9 text-hr" /> */}
        <div className="content">
          <div className="literacy">
            <h2 className="text-4xl mb-3 text-dblue py-3">Literacy</h2>
            <div className="develop oral language">
              <h2 className="text-xl  text-arniko-red ">
                Developing oral language (listening and speaking)
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    use language to express their needs, feelings and opinions
                  </li>
                  <li>
                    use gestures, actions, body language, words to communicate
                    needs and to express ideas
                  </li>
                  <li>
                    tell their own stories using words, gestures and
                    objects/artifacts
                  </li>
                </ol>
              </p>
            </div>

            <div className="Developing visual communication py-4 ">
              <h2 className="text-xl  text-arniko-red ">
                Developing visual communication (viewing and presenting)
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    show own feelings in response to visual presentations e.g
                    surprise, curiosity, amusement
                  </li>
                  <li>
                    use body language to communicate and to convey understanding
                    e.g. pointing, gesturing, facial expressions
                  </li>
                  <li>
                    tell their own stories using words, gestures and
                    objects/artifacts
                  </li>
                </ol>
              </p>
            </div>
            <div className="Developing as readers py-4  ">
              <h2 className="text-xl  text-arniko-red ">
                Developing as readers
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    listen, enjoy and respond to stories listening to stories
                  </li>
                  <li>choose and “read” picture books for pleasure</li>
                  <li>participate in shared reading, joining in with rhymes</li>
                  <li>recognize their own first name</li>
                  <li>
                    begin to discriminate between symbols, numbers, letters and
                    words
                  </li>
                </ol>
              </p>
            </div>
            <div className="	Developing as writers py-4 ">
              <h2 className="text-xl  text-arniko-red ">
                Developing as writers
              </h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    write letter like symbols, random strings of letters or
                    letters from their own name
                  </li>
                  <li>differentiate between illustrations and written text</li>
                  <li>
                    use their own experience as a stimulus when drawing and
                    “writing”
                  </li>
                  <li>“write” their own name independently</li>
                  <li>
                    hold a marker or crayon with fist fingers to make marks
                  </li>
                </ol>
              </p>
            </div>
          </div>

          <div className="	Numeracy py-6">
            <h2 className="text-4xl text-dblue py-1">Numeracy</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li> understand one-to-one correspondence </li>
                <li>
                  connect number names and numerals to quantities of objects in
                  small and large groups
                </li>
                <li> identify, describe and create patterns</li>
                <li>
                  understand that numbers can be constructed in multiple ways,
                  e.g. by combining and partitioning
                </li>
                <li>
                  count to 5 and beyond by rote e.g. when singing a counting
                  song
                </li>
                <li>find patterns in everyday situations</li>
                <li>
                  distinguish between and compare different attributes of
                  objects, e.g. longer, shorter, heavier, empty, full, hotter,
                  colder
                </li>
                <li> sort, match and describe shapes of familiar objects</li>
                <li>
                  gather information about themselves and their surroundings in
                  different way
                </li>
              </ol>
            </p>
          </div>
          <div className="Sanskrit Language py-6">
            <h2 className="text-4xl text-dblue py-1"> Sanskrit Language</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li> recognise that Sanskrit is an ancient language</li>
                <li> Recite and remember simple slokas</li>
                <li>
                  use simple words and language connected to everyday situations
                  to express their needs, feelings and opinions
                </li>
                <li>listen and enjoy interacting during read aloud</li>
              </ol>
            </p>
          </div>
          <div className="	Social Emotional py-6">
            <h2 className="text-4xl text-dblue py-1"> Social Emotional</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li>
                  identify and label feelings with a teacher or trusted friend
                </li>
                <li>
                  choose and get involved in one activity from several options
                </li>
                <li>
                  {" "}
                  enjoy interacting and playing cooperatively with others
                </li>
                <li>
                  is aware of other children’s feelings and often responds
                  appropriately
                </li>
                <li>○follow routines with increasing independence</li>
                <li> initiate physical activities and challenges</li>
              </ol>
            </p>
          </div>

          <div className="		Science & Technology py-6">
            <h2 className="text-4xl text-dblue py-1"> Science & Technology</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li> explore objects to observe specific attributes </li>
                <li>
                  use non-standard tools, such as blocks, hands, pencils to
                  measure and collect data
                </li>
                <li>
                  discuss what is observed and respond to questions regarding
                  attributes of objects
                </li>
                <li>respond to the world around them by using their senses.</li>
                <li>○follow routines with increasing independence</li>
                <li>
                  Engage in scientific investigations by making observations etc
                  how does this work? What will happen?
                </li>
                <li> identify changes in their immediate environment</li>
                <li>
                  discuss possible outcomes and propose simple ideas to test
                </li>
                <li> sort and classify to draw simple conclusions</li>
                <li>
                  discuss and show observations within a scientific
                  investigation
                </li>
              </ol>
            </p>
          </div>

          <div className="	Creative Arts py-6">
            <h2 className="text-4xl mb-3 text-dblue py-3"> Creative Arts</h2>
            <div className="	Visual Arts">
              <h2 className="text-xl  text-arniko-red ">Visual Arts</h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    show enjoyment, curiosity and ask questions about an artwork{" "}
                  </li>
                  <li>
                    use imagination and experiences to inform own art making
                  </li>
                  <li>
                    participate in individual and collective creative
                    experiences
                  </li>
                </ol>
              </p>
            </div>

            <div className="	Dance/Drama py-2">
              <h2 className="text-xl  text-arniko-red "> Dance/Drama</h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    describe ideas and feelings communicated through body
                    movements
                  </li>
                  <li>
                    cooperate and communicate with others in creating dance
                  </li>
                </ol>
              </p>
            </div>
            <div className="	Music py-2  ">
              <h2 className="text-xl  text-arniko-red "> Music</h2>
              <p className="text-base py-3">
                <ol className="list-disc">
                  <li>
                    show enjoyment through singing, dancing, playing games and
                    instruments
                  </li>
                  <li>
                    experience ways of expressing themselves through creative
                    arts
                  </li>
                </ol>
              </p>
            </div>
          </div>

          <div className="		Design Thinking py-6	">
            <h2 className="text-4xl text-dblue py-1"> Design Thinking</h2>

            <p className="text-base py-3">
              <ol className="list-disc">
                <li>
                  understand that we can create with a wide range of materials
                  to invent, create or make new discoveries
                </li>
                <li>
                  know that we can brainstorm and ask for ideas from others to
                  improve our own thinking and designs
                </li>
                <li>
                  be able to modify their creations and designs to test their
                  own scientific theories, e.g. designing moving toys that
                  deepen their understanding of forces, energy, nature and
                  matter
                </li>
                <li>
                  experience the fun of creating and making products to build on
                  their interests, talents or curiosities
                </li>
              </ol>
            </p>
          </div>
        </div>
        {/* <Features
          title="Extra-curricular Programme"
          content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
        />
        <Features
          title="Primary School"
          content={`in Arts allows students to explore these disciplines further. Our students produce exciting theatre, perform in engaging musical concerts, and create innovative art across a spectrum of media. These Performing and Visual Arts programmes are sure to captivate your children whatever their artistic passions may be.`}
        />
        <Features
          title="Secondary School"
          content={`School provide continuity from the Primary School. Students explore the Arts as a means of both self-expression and, importantly, the expression of ideas. They continue to develop their understanding of diverse cultural traditions and the function of Arts in their own communities. Teamwork and collaboration also continue to be important points of focus and the risk-taking aspect of the Arts is enhanced through greater opportunities to performances for the school community.`}
        /> */}
        <div className="text-center py-4 mb-9">
          {/* <h2 className="text-2xl  text-arniko-red ">
            Unleash your child's artistic voice
          </h2> */}
          <div className="flex gap-2 justify-center py-4">
            <Link to="/form">
              <Filled tit="ADMISSIONS" />
            </Link>
            {/* <Filled tit="PLAN A VISIT" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Primary;
