const MainImg = (props) => {
  return (
    <div>
      <section
        className="w-full px-lg flex items-center"
        style={{
          backgroundImage: `url(${props.content})`,
          height: "40vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          minHeight: "400px",
        }}
      ></section>
    </div>
  );
};

export default MainImg;
