import TitleComponent from "../../components/common/TitleComponent";
import TeamMember1 from "../../assets/images/team/yogen.png";
import TeamMember2 from "../../assets/images/team/1.png";
import TeamMember3 from "../../assets/images/team/2.png";
import TeamMember4 from "../../assets/images/team/3.png";
import TeamMember5 from "../../assets/images/team/4.png";
import TeamMember6 from "../../assets/images/team/5.png";
import TeamMember7 from "../../assets/images/team/Bishnu Kc.png";
import TeamMember8 from "../../assets/images/team/Sudarsan Nepal.png";
import TeamMember9 from "../../assets/images/team/Radha Krishna Gajurel 1.png";
import HeaderAndParagraph from "../../components/common/HeaderAndParagraph";
import AboutBg from "../../assets/images/aboutbg.png";
import { useEffect } from "react";
import { usePopupContext } from "../../contextApi/PopupContextHandler";
import RightArrow from "../../assets/icons/right-arrow-svgrepo-com (1).svg";

const AboutUs = () => {
  const { setFirstTime, setStyleChange } = usePopupContext();

  useEffect(() => {
    setFirstTime(false);
    setStyleChange("auto");
  }, []);

  const teamMember = [
    {
      img: TeamMember1,
      name: "Yogendra Maharjan",
      position: "Chairman ",
    },
    {
      img: TeamMember3,
      name: "Ramesh Parajuli",
      position: "Vice Chairman  ",
    },
    {
      img: TeamMember8,
      name: "Sudarsan Nepal",
      position: "Principal/CEO ",
    },
    {
      img: TeamMember9,
      name: "Radha Krishna Gajurel",
      position: "Admin Director/ Secretary ",
    },
    {
      img: TeamMember5,
      name: "Er. Sabin Maharjan",
      position: "Chief Technical Officer ",
    },
    

    {
      img: TeamMember4,
      name: "Dipak Malla",
      position: "IQAC Co-ordinator ",
    },
    
    {
      img: TeamMember6,
      name: "Chhetra Bahadur Chhetri",
      position: "Board of Director ",
    },
    {
      img: TeamMember2,
      name: "Er. Saroj Thapa",
      position: "Research Head ",
    },
    {
      img: TeamMember7,
      name: "Er. Bishnu Kc",
      position: "Co-curricular Head ",
    },

    
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <div className="about-hero-section flex justify-center items-center flex-col  text-center">
        <div className="about-title">We are Arniko International Academy</div>
        <p className="desc">
          Arniko prepares its students to achieve academic as well as
          co-curricular excellence. We've adopted a teaching practice that
          ensures quality education, practical knowledge hub and builds an
          independent learning eco-system.
        </p>
        <p className="sub-desc">
          With our team of young, energetic, and dedicated faculty members, we
          are confident in redefining the education system and contributing to
          quality education.
        </p>
        <div
          className="btn-section flex items-center flex-wrap"
          style={{ margin: "20px 0" }}
        >
          <a href="#vision">know more about us</a>
          <div style={{ marginLeft: "20px" }}>
            <a href="#teams">meet our team</a>
          </div>
        </div>
      </div>
      <div className="vision" id="vision">
        <div className="main-container">
          {/* <div className="vison-section">
            <HeaderAndParagraph
              title="Our Vision"
              desc="Our vision is to be a student-centric learning institution where students discover their natural talents and skills as a part of the learning process."
            />
          </div> */}
          <div className="mission-section flex space-between items-center flex-wrap">
            <div className="mission-contents">
              <div className="title">Our Vision</div>
              <p style={{ marginBottom: "20px" }}>
                Our vision is to be a student-centric learning institution where
                students discover their natural talents and skills as a part of
                the learning process.
              </p>
              <div className="title">Our Mission</div>
              <p>
                We understand and respect the fact that not every student needs
                to sign up for a specific profession. Arniko becomes the perfect
                place where students scout their talent, skill, and creativity.
              </p>
              <li className="item flex">
                <div className="icon">
                  <img src={RightArrow} alt="icon" />
                </div>
                Incorporate talent development classes as a part of the major
                curriculum rather than extra or optional classes.
              </li>
              <li className="item flex">
                <div className="icon">
                  <img src={RightArrow} alt="icon" />
                </div>
                Provide a platform, support, and necessary tools to enhance an
                individual’s strength.
              </li>
              <li className="item flex">
                <div className="icon">
                  <img src={RightArrow} alt="icon" />
                </div>
                Nurture human values that contribute to becoming a responsible
                citizen.
              </li>
            </div>
            <div className="image-section">
              <img src={AboutBg} />
            </div>
          </div>
        </div>
      </div>
      {/* video tour  */}
      <div className="arniko-video-tour">
        <iframe
          className="arniko-video"
          src="https://www.youtube.com/embed/lyMvKtBnXjA?rel=0&autoplay=1&loop=1&modestbranding=1&rel=0"
          title="Arniko College Promotional Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      {/* team  */}
      <div className="meet-our-team text-center" id="teams">
        <TitleComponent title="Meet Our Team" />
        <div className="team-members flex justify-between items-center flex-wrap">
          {teamMember?.map((item: any, key: number) => (
            <div className="image-box" key={key}>
              <div className="image-div">
                <img src={item?.img} alt="team member" />
              </div>
              <div className="name-person">{item?.name}</div>
              <div className="position">{item?.position}</div>
              <div className="position">
                Arniko International SS And College
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
